import React from "react"
import { CircularProgress } from "@mui/material"

const Loading = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'  // Full viewport height
        }}>
            <CircularProgress
                size={40}
                width={10}
                fill={50}
            />
        </div>
    );
}

export default Loading