import {gql} from "@apollo/client";
import FilterType from "./components/FilterType";

export const DELETE_ACCREDITATION_FEE = gql`
  mutation DeleteAccreditationFee($id: Int!) {
    delete_vehicle_accreditation_fee_history(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const DELETE_MEMBERSHIP_FEE = gql`
  mutation DeleteMembershipFee($id: Int!) {
    delete_vechicle_membership_fee_history(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const DELETE_ENTRY_FEE = gql`
  mutation DeleteEntryFee($id: Int!) {
    delete_vehicle_entry_fee_history(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const UPDATE_ACCREDITATION_FEE = gql`
mutation UpdateAccreditationFeeHistory(
  $id: Int!,
  $amount: Int!,
  $fk_admin_id: Int!,
  $fk_vehicle_plate_number: String!,
  $payment_type: String!
) {
  update_vehicle_accreditation_fee_history(
    where: { id: { _eq: $id } },
    _set: {
      amount: $amount,
      fk_admin_id: $fk_admin_id,
      fk_vehicle_plate_number: $fk_vehicle_plate_number,
      payment_type: $payment_type
    }
  ) {
    affected_rows
  }
}`;

export const UPDATE_MEMBERSHIP_FEE = gql`
mutation UpdateMembershipFeeHistory(
  $id: Int!,
  $amount: Int!,
  $fk_admin_id: Int!,
  $fk_vehicle_plate_number: String!,
  $payment_type: String!
) {
  update_vechicle_membership_fee_history(
    where: { id: { _eq: $id } },
    _set: {
      amount: $amount,
      fk_admin_id: $fk_admin_id,
      fk_vehicle_plate_number: $fk_vehicle_plate_number,
      payment_type: $payment_type
    }
  ) {
    affected_rows
  }
}`;

export const UPDATE_VEHICLE_ENTRY_FEE = gql`
  mutation UpdateVehicleEntryFee(
    $id: Int!, 
    $destination: String!, 
    $fee_amount: Int!, 
    $fk_barrier_gate_id: Int!, 
    $fk_vehicle_plate_number: String!
  ) {
    update_vehicle_entry_fee_history(
      where: {id: {_eq: $id}}, 
      _set: {
        destination: $destination, 
        fee_amount: $fee_amount, 
        fk_barrier_gate_id: $fk_barrier_gate_id, 
        fk_vehicle_plate_number: $fk_vehicle_plate_number
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_ACCREDITATION_FEE_DETAILS = gql`
query MyQuery($id: Int!) {
  detail: vehicle_accreditation_fee_history(where: {id: {_eq: $id}}) {
    amount
    fk_admin_id
    fk_vehicle_plate_number
    id
    payment_timestamp
    payment_type
  }
}`

export const GET_MEMBERSHIP_FEE_DETAILS = gql`
  query MyQuery($id: Int!) {
    detail: vechicle_membership_fee_history(where: {id: {_eq: $id}}) {
      payment_type
      payment_timestamp
      id
      fk_vehicle_plate_number
      fk_admin_id
      amount
    }
  }
`;

export const GET_VEHICLE_ENTRY_FEE_DETAILS = gql`
  query GetVehicleEntryFeeDetails($id: Int!) {
    detail: vehicle_entry_fee_history(where: {id: {_eq: $id}}) {
      destination
      fee_amount
      fk_admin_id
      fk_barrier_gate_id
      fk_vehicle_plate_number
      payment_type
    }
  }
`;

export const GET_BARRIER_GATE_DETAILS = gql`
    query GetBarrierGateAccount($id: Int!) {
        detail: barrier_gate_accounts(where: {id: {_eq: $id}}) {
            barrier_gate_identifier
            id
        }
    }
`;

export const GET_ENTRY_FEE_DETAILS = gql`
  query MyQuery($id: Int!) {
    detail: vehicle_entry_fee_history(where: {id: {_eq: $id}}) {
      destination
      fee_amount
      fk_vehicle_plate_number
      fk_barrier_gate_id
      id
      payment_timestamp
      payment_type
      barrier_gate_account {
      barrier_gate_identifier
      fk_transport_terminal_name
    }
    }
  }
`;

export const GET_TERMINAL_DETAILS = gql`
  query MyQuery($terminalId: Int!) {
    transport_terminal_information(
      where: { transport_terminal_id: { _eq: $terminalId } }
    ) {
      contactor_number
      email_address
      monthly_fee_amount
      transport_terminal_address
      transport_terminal_name
      accreditation_fee_amount
    }
  }
`;

export const DELETE_TERMINAL_DETAILS = gql`
mutation DeleteTerminal($terminalId: Int!) {
  delete_transport_terminal_information(
    where: { transport_terminal_id: { _eq: $terminalId } }
  ) {
    affected_rows
  }
}`;

export const UPDATE_TERMINAL_DETAILS = gql`
mutation MyMutation(
  $terminalId: Int!, 
  $accreditationFee: Int, 
  $contactorNumber: String, 
  $emailAddress: String, 
  $monthlyFee: Int, 
  $terminalAddress: String, 
  $terminalName: String
) {
  update_transport_terminal_information(
    where: { transport_terminal_id: { _eq: $terminalId } }, 
    _set: { 
      accreditation_fee_amount: $accreditationFee, 
      contactor_number: $contactorNumber, 
      email_address: $emailAddress, 
      monthly_fee_amount: $monthlyFee, 
      transport_terminal_address: $terminalAddress, 
      transport_terminal_name: $terminalName 
    }
  ) {
    affected_rows
  }
}`;

export const DELETE_VEHICLE_ENTRY_FEE_VALUES = gql`
  mutation DeleteVehicleEntryFeeValues($id: Int!) {
    delete_vehicle_entry_fee_values(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const GET_ADMIN_NAME_BY_ID = gql`
  query GetAdminAccount($id: Int!) {
    admin_accounts(where: { id: { _eq: $id } }) {
      full_name
      id
    }
  }
`;

export const UPDATE_ENTRY_FEE_VALUE = gql`
  mutation UpdateVehicleEntryFeeValues(
    $id: Int!,
    $entryFeeValue: Int!,
    $adminId: Int!,
    $destinationName: String!,
    $transportTerminalName: String!
  ) {
    update_vehicle_entry_fee_values(
      where: {id: {_eq: $id}},
      _set: {
        entry_fee_value: $entryFeeValue,
        fk_admin_id: $adminId,
        fk_destination_name: $destinationName,
        fk_transport_terminal_name: $transportTerminalName
      }
    ) {
      affected_rows
    }
  }
`;


export const ADD_ENTRY_FEE_VALUE = gql`
mutation MyMutation(
  $fk_admin_id: Int!,
  $fk_destination_name: String!,
  $fk_transport_terminal_name: String!,
  $entry_fee_value: Int!
) {
  insert_vehicle_entry_fee_values(
    objects: {
      fk_admin_id: $fk_admin_id,
      fk_destination_name: $fk_destination_name,
      fk_transport_terminal_name: $fk_transport_terminal_name,
      entry_fee_value: $entry_fee_value
    }
  ) {
    affected_rows
  }
}`;

export const GET_ALL_ENTRY_FEE_VALUE = gql`
query MyQuery (
  $limit: Int!,
  $offset: Int!
) {
  vehicle_entry_fee_values (
    limit: $limit,
    offset: $offset,
    order_by: {created_at: desc},
  ) {
    entry_fee_value
    fk_admin_id
    fk_destination_name
    fk_transport_terminal_name
    id
    created_at
  }
  total: vehicle_entry_fee_values_aggregate {
    aggregate {
      count
    }
  }
}`;

export const GET_ALL_ACCREDITATION_FEE = gql`
query MyQuery (
    $offset: Int!
    $limit: Int!
    ) {
  vehicle_accreditation_fee_history (
  limit: $limit
  offset: $offset
  ) {
    amount
    payment_type
    fk_admin_id
    fk_vehicle_plate_number
    updated_at
    id
    payment_timestamp
    created_at
  }
    vehicle_accreditation_fee_history_aggregate {
    aggregate {
      count
    }
  }
}
`;

export const GET_ALL_MEMEBERSHIP_FEE = gql`
query MyQuery {
   vechicle_membership_fee_history{
    amount
    created_at
    fk_admin_id
    fk_vehicle_plate_number
    id
    payment_timestamp
    payment_type
    updated_at
  }
}
`

export const GET_ALL_ENTRY_FEE = gql`
query MyQuery {
  vehicle_entry_fee_history {
    barrier_gate_account {
      fk_transport_terminal_name
    }
    payment_timestamp
    id
    fk_vehicle_plate_number
    fk_barrier_gate_id
    fee_amount
    destination
  }
}
`

export const GET_ADMIN_ID = gql`
query MyQuery ($id: Int!){
  vehicle_accreditation_fee_history(where: {amount: {_eq: $id}}) {
    amount
  }
}
`

export const GET_ALL_ACCREDITATION_FEE_BY_FILTERS = gql`
query MyQuery(
  $fk_admin_id: Int,
  $fk_vehicle_plate_number: String,
  $id: Int
) {
  vehicle_accreditation_fee_history(
    where: {
      fk_admin_id: {_eq: $fk_admin_id},
      fk_vehicle_plate_number: {_iregex: $fk_vehicle_plate_number},
      id: {_eq: $id},
    }
  ) {
    amount
    fk_admin_id
    fk_vehicle_plate_number
    id
    payment_timestamp
    payment_type
  }
}
`;

export const createTerminalFilterQuery = (type) => {
    let query = null;
    switch (type) {
        case FilterType.FEE_VALUE_TERMINAL_NAME: {
            query = gql`
        query MyQuery($parameter: String!) {
          suggestions: transport_terminal_information(
            distinct_on: transport_terminal_name,
            limit: 5,
            where: { transport_terminal_name: { _ilike: $parameter } }
          ) {
            name: transport_terminal_name
          }
        }
      `;
            break;
        }
        case FilterType.TERMINAL_ADDRESS: {
            query = gql`
        query MyQuery($parameter: String!) {
          suggestions: transport_terminal_information(
            distinct_on: transport_terminal_address,
            limit: 5,
            where: { transport_terminal_address: { _ilike: $parameter } }
          ) {
            name: transport_terminal_address
          }
        }
      `;
            break;
        }
        case FilterType.EMAIL_ADDRESS: {
            query = gql`
        query MyQuery($parameter: String!) {
          suggestions: transport_terminal_information(
            distinct_on: email_address,
            limit: 5,
            where: { email_address: { _ilike: $parameter } }
          ) {
            name: email_address
          }
        }
      `;
            break;
        }
        case FilterType.CONTACTOR_NUMBER: {
            query = gql`
        query MyQuery($parameter: String!) {
          suggestions: transport_terminal_information(
            distinct_on: contactor_number,
            limit: 5,
            where: { contactor_number: { _ilike: $parameter } }
          ) {
            name: contactor_number
          }
        }
      `;
            break;
        }
        case FilterType.ACCREDITATION_FEE: {
            query = gql`
        query MyQuery($parameter: Int!) {
          suggestions: transport_terminal_information(
          distinct_on: accreditation_fee_amount,
            limit: 5,
            where: { accreditation_fee_amount: { _eq: $parameter } }
          ) {
            name: accreditation_fee_amount
          }
        }
      `;
            break;
        }
        case FilterType.MEMBERSHIP_FEE: {
            query = gql`
        query MyQuery($parameter: Int!) {
          suggestions: transport_terminal_information(
          distinct_on: monthly_fee_amount,
            limit: 5,
            where: { monthly_fee_amount: { _eq: $parameter } }
          ) {
            name: monthly_fee_amount
          }
        }
      `;
            break;
        }
    }
    return query;
}

export const createTerminalUpdateQuery = (type) => {
    let query = null;
    switch (type) {
        case FilterType.FEE_VALUE_TERMINAL_NAME: {
            query = gql`
        query MyQuery($parameter: String!) {
          transport_terminal_information(
            distinct_on: transport_terminal_name,
            limit: 5,
            where: { transport_terminal_name: { _ilike: $parameter } }
          ) {
            transport_terminal_name
          }
        }
      `;
            break;
        }
        case FilterType.TERMINAL_ADDRESS: {
            query = gql`
        query MyQuery($parameter: String!) {
          transport_terminal_information(
            distinct_on: transport_terminal_address,
            limit: 5,
            where: { transport_terminal_address: { _ilike: $parameter } }
          ) {
            transport_terminal_address
          }
        }
      `;
            break;
        }
        case FilterType.EMAIL_ADDRESS: {
            query = gql`
        query MyQuery($parameter: String!) {
          transport_terminal_information(
            distinct_on: email_address,
            limit: 5,
            where: { email_address: { _ilike: $parameter } }
          ) {
            email_address
          }
        }
      `;
            break;
        }
        case FilterType.CONTACTOR_NUMBER: {
            query = gql`
        query MyQuery($parameter: String!) {
          transport_terminal_information(
            distinct_on: contactor_number,
            limit: 5,
            where: { contactor_number: { _ilike: $parameter } }
          ) {
            contactor_number
          }
        }
      `;
            break;
        }
        case FilterType.ACCREDITATION_FEE: {
            query = gql`
        query MyQuery($parameter: Int!) {
          transport_terminal_information(
          distinct_on: accreditation_fee_amount,
            limit: 5,
            where: { accreditation_fee_amount: { _eq: $parameter } }
          ) {
            accreditation_fee_amount
          }
        }
      `;
            break;
        }
        case FilterType.MEMBERSHIP_FEE: {
            query = gql`
        query MyQuery($parameter: Int!) {
          transport_terminal_information(
          distinct_on: monthly_fee_amount,
            limit: 5,
            where: { monthly_fee_amount: { _eq: $parameter } }
          ) {
            monthly_fee_amount
          }
        }
      `;
            break;
        }
    }
    return query;
}

export const createEntryFeeValueQuery = (type) => {
    let query = null;
    switch (type) {
        case FilterType.FEE_VALUE_TERMINAL_NAME: {
            query = gql`
        query MyQuery($parameter: String!) {
          transport_terminal_information(
            distinct_on: transport_terminal_name,
            limit: 5,
            where: { transport_terminal_name: { _ilike: $parameter } }
          ) {
            transport_terminal_name
          }
        }
      `;
            break;
        }
        case FilterType.FEE_VALUE_DESTINATION: {
            query = gql`
        query MyQuery($parameter: String!) {
          transport_terminal_information(
            distinct_on: transport_terminal_name,
            limit: 5,
            where: { transport_terminal_name: { _ilike: $parameter } }
          ) {
            transport_terminal_name
          }
        }
      `;
            break;
        }
        case FilterType.FEE_ADMIN_ID: {
            query = gql`
        query MyQuery($parameter: String!) {
          admin_accounts(limit: 5, where: { username: { _ilike: $parameter } }) {
            id
            username
          }
        }
      `;
            break;
        }

    }
    return query;
};

export const createSearchQuery = (type) => {
    let query = '';
    switch (type) {
        case FilterType.ALL_PLATE_NUMBER:
            query = gql`
        query MyQuery($parameter: String!) {
          suggestions: vehicle_accounts(
            where: { vehicle_plate_number: { _ilike: $parameter } },
            limit: 5,
            distinct_on: vehicle_plate_number
          ) {
            name: vehicle_plate_number
          }
        }
      `;
            break;

        case FilterType.ALL_BARRIER_GATE_ID:
            query = gql`
        query MyQuery($parameter: String!) {
          suggestions: barrier_gate_accounts(
            where: { barrier_gate_identifier: { _ilike: $parameter } },
            limit: 5,
            distinct_on: barrier_gate_identifier
          ) {
            name: barrier_gate_identifier
            id
          }
        }
      `;
            break;

        case FilterType.ALL_DESTINATION:
            query = gql`
        query MyQuery($parameter: String!) {
          suggestions: transport_terminal_information(
            where: { transport_terminal_name: { _ilike: $parameter } },
            limit: 5,
            distinct_on: transport_terminal_name
          ) {
            name: transport_terminal_name
          }
        }
      `;
            break;

        case FilterType.ALL_ADMIN_ID:
            query = gql`
        query MyQuery($parameter: String!) {
          suggestions: admin_accounts(
            where: { full_name: { _ilike: $parameter } },
            limit: 10,
            distinct_on: full_name
          ) {
            name: full_name
            id
          }
        }
      `;
            break;

        default:
            break;
    }
    return query;
};



export const createDynamicQuery = (type) => {
    let query = null;
    switch (type) {
        case FilterType.ID: {
            query = gql`
        query MyQuery($parameter: Int!) {
          vehicle_accreditation_fee_history(distinct_on: id,limit: 5, where: { id: { _eq: $parameter } }) {
            id
          }
        }
      `;
            break;
        }
        case FilterType.VEHICLE_NUMBER: {
            query = gql`
      query MyQuery($parameter: String!) {
          vehicle_accreditation_fee_history(distinct_on: fk_vehicle_plate_number, limit: 5, where: { fk_vehicle_plate_number: { _ilike: $parameter } }) {
            fk_vehicle_plate_number
          }
        }
    `;
            break;
        }
        case FilterType.AMOUNT:
            query = gql`
        query MyQuery($parameter: Int!) {
          vehicle_accreditation_fee_history(limit: 5, where: { amount: { _eq: $parameter } }) {
            amount
          }
        }
      `;
            break;
        case FilterType.PAYMENT_TYPE:
            query = gql`
        query MyQuery($parameter: String!) {
          vehicle_accreditation_fee_history(distinct_on: payment_type, limit: 5, where: { payment_type: { _ilike: $parameter } }) {
            payment_type
          }
        }
      `;
            break;
        case FilterType.ADMIN_ID:
            query = gql`
        query MyQuery($parameter: Int!) {
          vehicle_accreditation_fee_history(distinct_on: fk_admin_id, limit: 5, where: { fk_admin_id: { _eq: $parameter } }) {
            fk_admin_id
          }
        }
      `;
            break;
        default:
            query = null;
            break;
    }
    return query;
};

export const createMemberShipQuery = (type) => {
    let query = null;
    switch (type) {
        case FilterType.ID:
            query = gql`
        query MyQuery($parameter: Int!) {
          vechicle_membership_fee_history(distinct_on: id, limit: 5, where: { id: { _eq: $parameter } }) {
            id
          }
        }
      `;
            break;
        case FilterType.VEHICLE_NUMBER:
            query = gql`
        query MyQuery($parameter: String!) {
          vechicle_membership_fee_history(distinct_on: fk_vehicle_plate_number, limit: 5, where: { fk_vehicle_plate_number: { _ilike: $parameter } }) {
            fk_vehicle_plate_number
          }
        }
      `;
            break;
        case FilterType.AMOUNT:
            query = gql`
        query MyQuery($parameter: Int!) {
          vechicle_membership_fee_history(distinct_on: amount, limit: 5, where: { amount: { _eq: $parameter } }) {
            amount
          }
        }
      `;
            break;
        case FilterType.PAYMENT_TYPE:
            query = gql`
        query MyQuery($parameter: String!) {
          vechicle_membership_fee_history(distinct_on: payment_type, limit: 5, where: { payment_type: { _ilike: $parameter } }) {
            payment_type
          }
        }
      `;
            break;
        case FilterType.ADMIN_ID:
            query = gql`
        query MyQuery($parameter: Int!) {
          vechicle_membership_fee_history(distinct_on: fk_admin_id, limit: 5, where: { fk_admin_id: { _eq: $parameter } }) {
            fk_admin_id
          }
        }
      `;
            break;

        default:
            throw new Error('Invalid filter type');
    }
    return query;
};

export const createEntryFeeQuery = (type) => {
    let query = null;
    switch (type) {
        case FilterType.ID:
            query = gql`
        query MyQuery($parameter: Int!) {
          vehicle_entry_fee_history(distinct_on: id, limit: 5, where: { id: { _eq: $parameter } }) {
            id
          }
        }
      `;
            break;
        case FilterType.VEHICLE_NUMBER:
            query = gql`
        query MyQuery($parameter: String!) {
          vehicle_entry_fee_history(distinct_on: fk_vehicle_plate_number, limit: 5,where: { fk_vehicle_plate_number: { _ilike: $parameter } }) {
            fk_vehicle_plate_number
          }
        }
      `;
            break;
        case FilterType.TERMINAL_NAME:
            query = gql`
        query MyQuery ($parameter: String!) {
  vehicle_entry_fee_history(limit: 5, where: {barrier_gate_account: {fk_transport_terminal_name: {_ilike: $parameter}}}) {
    barrier_gate_account {
      fk_transport_terminal_name
    }
  }
}
      `;
            break;
        case FilterType.DESTINATION:
            query = gql`
        query MyQuery($parameter: String!) {
          vehicle_entry_fee_history(distinct_on: destination, limit: 5,where: { destination: { _ilike: $parameter } }) {
            destination
          }
        }
      `;
            break;
        case FilterType.BARRIER_GATE_ID:
            query = gql`
        query MyQuery($parameter: Int!) {
          vehicle_entry_fee_history(where: { fk_barrier_gate_id: { _eq: $parameter } }) {
            fk_barrier_gate_id
          }
        }
      `;
            break;
        default:
            throw new Error('Invalid filter type');
    }
    return query;
};

export const PROFILE = gql`
  query MyQuery($id: Int!) {
    admin_accounts(where: { id: { _eq: $id } }) {
      active
      address
      full_name
      admin_category
      phone
      username
      fk_assigned_terminal_id
      wallet_value
    }
  }
`;

export const TRANSPORTTERMINALS = gql`
  query MyQuery($offset: Int!, $limit: Int!, $search: String!) {
    transport_terminal_information(
      where: { transport_terminal_name: { _ilike: $search } }
      offset: $offset
      limit: $limit
    ) {
      transport_terminal_name
      transport_terminal_address
      transport_terminal_id
      email_address
      contactor_number
      accreditation_fee_amount
      monthly_fee_amount
    }
    transport_terminal_information_aggregate(
      where: { transport_terminal_name: { _ilike: $search } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const TERMINALS = gql`
  query MyQury {
    transport_terminal_information {
      transport_terminal_name
      transport_terminal_id
    }
  }
`;

export const ADMINS = gql`
  query MyQuery($limit: Int!, $offset: Int!, $search: String!, $role: String) {
    admin_accounts(
      limit: $limit
      offset: $offset
      where: {
        _and: [
          { full_name: { _ilike: $search } }
          { admin_category: { _ilike: $role } }
        ]
      }
    ) {
      active
      address
      admin_category
      fk_assigned_terminal_id
      full_name
      id
      phone
      username
    }
    admin_accounts_aggregate(
      where: {
        _and: [
          { full_name: { _ilike: $search } }
          { admin_category: { _ilike: $role } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ONOFADMIN = gql`
  mutation MyMutation($username: String!, $active: Boolean!) {
    update_admin_accounts(
      where: { username: { _eq: $username } }
      _set: { active: $active }
    ) {
      returning {
        username
        active
      }
    }
  }
`;

export const DRIVERS = gql`
  query MyQuery($search: String) {
    driver(
      where: {
        _or: [
          { first_name: { _ilike: $search } }
          { last_name: { _ilike: $search } }
        ]
      }
      order_by: { driver_id: desc }
    ) {
      driver_id
      active
      address
      biometrics_photo_fingerprint
      date_of_birth
      email_address
      first_name
      gender
      last_name
      phone
      driving_license_number
      vehicle_accounts {
        vehicle_plate_number
        fk_vehicle_owner
        admin_account {
          full_name
        }
      }
    }
  }
`;

export const DRIVERSNAME = gql`
  query MyQuery($search: String) {
    driver {
      first_name
      last_name
      driver_id
    }
  }
`;

export const APPROVEDRIVER = gql`
  mutation MyMutation($id: Int, $isActivate: Boolean) {
    update_driver(
      where: { driver_id: { _eq: $id } }
      _set: { active: $isActivate }
    ) {
      affected_rows
    }
  }
`;

export const VEHICLES = gql`
  query MyQuery($search: String, $limit: Int, $offset: Int) {
    vehicle_accounts(
      limit: $limit
      offset: $offset
      order_by: { active: asc }
    ) {
      fk_assigned_segment_route_id
      fk_driver_id
      active
      admin_account {
        full_name
      }
      driver {
        driver_id
        address
        date_of_birth
        email_address
        first_name
        gender
        last_name
        phone
        driving_license_number
        active
      }
      fk_vehicle_type
      vehicle_plate_number
      fk_operator_id
      vehicle_rfid_tag
    }
    vehicle_accounts_aggregate {
      aggregate {
        count
      }
    }
  }
`;

// export const VEHICLESWITHFILTER = gql`
//   query MyQuery($search: String, $name: Int) {
//     vehicle_accounts(
//       where: {
//         _and: [
//           { vehicle_plate_number: { _like: $search } }
//           { fk_driver_id: { _eq: $name } }
//         ]
//       }
//       order_by: { active: asc }
//     ) {
//       fk_assigned_segment_route_id
//       fk_driver_id
//       active
//       admin_account {
//         full_name
//       }
//       driver {
//         first_name
//         last_name
//       }
//       fk_vehicle_type
//       vehicle_plate_number
//       fk_operator_id
//     }
//   }
// `;

export const VEHICLESWITHFILTER = gql`
  query MyQuery($search: String, $name: String) {
    vehicle_accounts(
      where: {
        _and: [
          { vehicle_plate_number: { _ilike: $search } }
          { admin_account: { full_name: { _ilike: $name } } }
        ]
      }
      order_by: { active: asc }
    ) {
      fk_assigned_segment_route_id
      fk_driver_id
      active
      admin_account {
        full_name
      }
      driver {
        first_name
        last_name
      }
      fk_vehicle_type
      vehicle_plate_number
      fk_operator_id
      vehicle_rfid_tag
    }
  }
`;

export const APPROVEDVEHICLES = gql`
  query MyQuery($search: String) {
    vehicle_accounts(
      where: {
        _and: [
          { active: { _eq: true } }
          { vehicle_plate_number: { _ilike: $search } }
        ]
      }
    ) {
      fk_assigned_segment_route_id
      fk_driver_id
      active
      admin_account {
        full_name
      }
      fk_vehicle_type
      vehicle_plate_number
      vehicle_rfid_tag
    }
  }
`;

export const APPROVEVEHICLE = gql`
  mutation MyMutation($plateNumber: String!, $isApprove: Boolean) {
    update_vehicle_accounts(
      where: { vehicle_plate_number: { _eq: $plateNumber } }
      _set: { active: $isApprove }
    ) {
      affected_rows
    }
  }
`;

export const THEME = gql`
  query MyQuery {
    oro_app_settings {
      appBackground
      app_type
      buttonColor1
      buttonColor2
      buttonColor3
      buttonColor4
      iconColor
      logoColor
      logoURL
      modalBackground
      textColor1
      textColor2
      textColor3
      walletBackground
    }
  }
`;

export const CHANGETHEME = gql`
  mutation MyMutation(
    $appBackground: String
    $appType: String
    $b1: String
    $b2: String
    $b3: String
    $b4: String
    $i: String
    $LC: String
    $LU: String
    $MBackground: String
    $t1: String
    $t2: String
    $t3: String
    $WBackground: String
  ) {
    update_oro_app_settings(
      where: { app_type: { _eq: $appType } }
      _set: {
        appBackground: $appBackground
        buttonColor1: $b1
        buttonColor2: $b2
        buttonColor3: $b3
        buttonColor4: $b4
        iconColor: $i
        logoColor: $LC
        logoURL: $LU
        modalBackground: $MBackground
        textColor1: $t1
        textColor2: $t2
        textColor3: $t3
        walletBackground: $WBackground
      }
    ) {
      affected_rows
    }
  }
`;

export const CREATETERMINAL = gql`
  mutation MyMutation(
    $email: String!
    $address: String!
    $name: String!
    $contactor: String!
  ) {
    insert_transport_terminal_information_one(
      object: {
        email_address: $email
        transport_terminal_address: $address
        transport_terminal_name: $name
        contactor_number: $contactor
      }
    ) {
      transport_terminal_address
      transport_terminal_id
      transport_terminal_name
      email_address
      created_date
      contactor_number
    }
  }
`;

export const UPDATETERMINAL = gql`
  mutation MyMutation2($oldName: String!, $newName: String!) {
    update_transport_terminal_information(
      where: { transport_terminal_name: { _eq: $oldName } }
      _set: { transport_terminal_name: $newName }
    ) {
      returning {
        transport_terminal_name
        transport_terminal_id
        transport_terminal_address
        email_address
        created_date
        contactor_number
      }
    }
  }
`;

// ptpt_trips not found
export const PASSENGERSANDDRIVERSBYPTP = gql`
  query MyQuery($id: Int) {
    ptp_trips(where: { id: { _eq: $id } }) {
      vehicle_account {
        fk_driver_id
        fk_vehicle_owner
        fk_vehicle_type
        vehicle_plate_number
      }
    }
    ptp_tickets(
      where: { fk_ptp_trip_id: { _eq: $id }, boarded: { _eq: true } }
    ) {
      boarded
      fk_ptp_trip_id
      fk_registered_passenger_type_id
      fk_passenger_id
      id
      paid
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      seat_number
      ticket_cost
      updated_at
    }
  }
`;

export const NONPTPDRIVERBYPASSENGER = gql`
  query MyQuery($name: String) {
    segment_tickets(
      where: { registered_name: { _ilike: $name }, boarded: { _eq: true } }
    ) {
      fk_driver_id
      boarded
      destination
      fk_admin_id
      fk_passenger_id
      fk_registered_passenger_type_id
      fk_segment_main_route_trips_id
      id
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      start
      ticket_cost
      ticket_expire_time
      updated_at
    }
  }
`;

export const PASSENGERSSERVEDBYDRIVER = gql`
  query MyQuery($id: Int, $date: timestamptz) {
    segment_tickets(
      where: {
        fk_driver_id: { _eq: $id }
        updated_at: { _gte: $date }
        boarded: { _eq: true }
      }
    ) {
      fk_driver_id
      boarded
      destination
      fk_admin_id
      fk_passenger_id
      fk_registered_passenger_type_id
      fk_segment_main_route_trips_id
      id
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      start
      ticket_cost
      ticket_expire_time
      updated_at
    }
  }
`;

export const ADDPTPROUTES = gql`
  mutation MyMutation($start: Int!, $end: Int!, $tripLength: numeric!) {
    insert_ptp_route_one(
      object: {
        fk_end_terminal: $end
        fk_start_terminal: $start
        trip_length: $tripLength
      }
    ) {
      created_at
      end_terminal {
        transport_terminal_name
        transport_terminal_id
      }
      start_terminal {
        transport_terminal_name
        transport_terminal_id
      }
      trip_length
    }
  }
`;

export const UPDATEPTPROUTE = gql`
  mutation MyMutation($id: Int!, $tripLength: numeric!) {
    update_ptp_route(
      where: { id: { _eq: $id } }
      _set: { trip_length: $tripLength }
    ) {
      affected_rows
    }
  }
`;

export const CREATESEGMENTMAIN = gql`
  mutation MyMutation($locations: jsonb!) {
    insert_segment_main_routes_one(object: { included_locations: $locations }) {
      created_at
      id
    }
  }
`;

export const CREATESEGMENTSUBS = gql`
  mutation MyMutation($subSegments: [segment_sub_routes_insert_input!]!) {
    insert_segment_sub_routes(objects: $subSegments) {
      affected_rows
    }
  }
`;

export const SEGMENTS = gql`
  query MyQuery {
    segment_main_routes {
      included_locations
      id
    }
  }
`;

export const PASSENGERWALLETS = gql`
  query MyQuery {
    passenger_services_wallets {
      fk_passenger_service_id
      id
      passenger_service {
        service_name
        service_owner
      }
      wallet_value
      updated_at
    }
  }
`;

export const ADMINWALLETS = gql`
  query MyQuery {
    admin_accounts(where: { admin_category: { _eq: "oro_admin" } }) {
      wallet_value
      username
    }
  }
`;

export const PASSENGERBYNAME = gql`
  query MyQuery($search: String) {
    ptp_tickets(
      where: { registered_name: { _ilike: $search }, boarded: { _eq: true } }
    ) {
      fk_ptp_trip_id
      fk_registered_passenger_type_id
      fk_passenger_id
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      seat_number
      updated_at
    }
  }
`;
export const PASSENGERLOGS = gql`
  query MyQuery($id: Int) {
    ptp_tickets(where: { fk_ptp_trip_id: { _eq: $id } }) {
      id
      created_at
      fk_admin_id
      fk_passenger_id
      fk_registered_passenger_type_id
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      seat_number
      ticket_cost
      updated_at
      ticket_passenger_type {
        passenger_type_name
      }
    }
  }
`;

export const PASSENGERBYNAMEANDDATE = gql`
  query MyQuery($search: String, $date: timestamptz) {
    segment_tickets(
      where: {
        registered_name: { _ilike: $search }
        updated_at: { _gte: $date }
        boarded: { _eq: true }
      }
    ) {
      fk_driver_id
      destination
      fk_passenger_id
      fk_registered_passenger_type_id
      fk_segment_main_route_trips_id
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      start
      updated_at
    }
  }
`;

export const PASSENGERBYDRIVERANDDATE = gql`
  query MyQuery($id: Int, $date: timestamptz) {
    segment_tickets(
      where: {
        fk_driver_id: { _eq: $id }
        updated_at: { _gte: $date }
        boarded: { _eq: true }
      }
    ) {
      destination
      fk_passenger_id
      fk_registered_passenger_type_id
      fk_segment_main_route_trips_id
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      start
      updated_at
    }
  }
`;

export const ADMINSNAMEANDID = gql`
  query MyQuery($role: String) {
    admin_accounts(where: { admin_category: { _eq: $role } }) {
      admin_category
      id
      username
    }
  }
`;

//terminal manager

export const ROUTES = gql`
  query MyQuery($search: String) {
    ptp_route(
      where: {
        start_terminal: { transport_terminal_name: { _ilike: $search } }
      }
    ) {
      created_at
      id
      trip_length
      updated_at
      end_terminal {
        transport_terminal_name
        transport_terminal_id
      }
      start_terminal {
        transport_terminal_name
        transport_terminal_id
      }
    }
  }
`;

export const OPERATORROUTES = gql`
  query MyQuery {
    ptp_route {
      created_at
      id
      trip_length
      updated_at
      end_terminal {
        transport_terminal_name
        transport_terminal_id
      }
      start_terminal {
        transport_terminal_name
        transport_terminal_id
      }
    }
  }
`;

export const ATROUTES = gql`
  query MyQuery($id: Int!, $search: String) {
    ptp_route(
      where: {
        _and: [
          { start_terminal: { transport_terminal_id: { _eq: $id } } }
          { end_terminal: { transport_terminal_name: { _ilike: $search } } }
        ]
      }
    ) {
      created_at
      id
      trip_length
      updated_at
      end_terminal {
        transport_terminal_name
        transport_terminal_id
      }
      start_terminal {
        transport_terminal_name
        transport_terminal_id
      }
    }
  }
`;

export const TERMINALTRANSACTIONS = gql`
  query MyQuery(
    $id: Int!
  ) {
    pos_payment_history(
      where: { admin_account: { fk_assigned_terminal_id: { _eq: $id } } }
    ) {
      amount
      created_at
      fk_admin_id
      admin_account {
        username
        fk_assigned_terminal_id
      }
      fk_driver_service_id
      fk_passenger_service_id
      id
      note
      payment_type
    }
  }
`;

export const TERMINAL_TRANSACTIONS_WITH_DATES = gql`
  query MyQuery(
    $id: Int!,
    $start: timestamptz,
    $end: timestamptz
  ) {
    pos_payment_history(
      where: { admin_account: { fk_assigned_terminal_id: { _eq: $id },created_at: {_gte: $start, _lt: $end} } }
    ) {
      amount
      created_at
      fk_admin_id
      admin_account {
        username
        fk_assigned_terminal_id
      }
      fk_driver_service_id
      fk_passenger_service_id
      id
      note
      payment_type
    }
  }
`;

export const TRANSACTIONSWITHSTART = gql`
  query MyQuery(
    $limit: Int
    $offset: Int
    $name: String
    $start: timestamptz
    $end: timestamptz
  ) {
    pos_payment_history(
      limit: $limit
      offset: $offset
      order_by: { id: desc }
      where: {
        admin_account: {
          transport_terminal_information: {
            transport_terminal_name: { _ilike: $name }
          }
        }
        created_at: { _gte: $start, _lt: $end }
      }
    ) {
      amount
      created_at
      fk_admin_id
      admin_account {
        username
        fk_assigned_terminal_id
      }
      fk_driver_service_id
      fk_passenger_service_id
      id
      note
      payment_type
    }
    pos_payment_history_aggregate(
      where: {
        admin_account: {
          transport_terminal_information: {
            transport_terminal_name: { _ilike: $name }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
// export const TRANSACTIONSWITHSTARTEND = gql`
// query MyQuery ($limit: Int, $offset: Int, $name: String, $start: timestamptz, $end: timestamptz) {
//   pos_payment_history (limit: $limit, offset: $offset, order_by: { id: desc },
//     where: {
//       admin_account: {transport_terminal_information: {transport_terminal_name: {_like: $name}}},
//       created_at: {_gte: $start, _lt: $end}
//     }
//   ) {
//     amount
//     created_at
//     fk_admin_id
//     fk_driver_service_id
//     fk_passenger_service_id
//     id
//     note
//     payment_type
//   }
//   pos_payment_history_aggregate (where: {admin_account: {transport_terminal_information: {transport_terminal_name: {_like: $name}}}}) {
//     aggregate {
//       count
//     }
//   }
// }
// `

export const TRANSACTIONSREMOVE = gql`
  mutation MyMutation($id: Int!) {
    delete_pos_payment_history_by_pk(id: $id) {
      id
    }
  }
`;

export const TRANSACTIONSWITHFILTER = gql`
  query MyQuery($limit: Int, $offset: Int, $name: String) {
    pos_payment_history(
      limit: $limit
      offset: $offset
      order_by: { id: desc }
      where: {
        admin_account: {
          transport_terminal_information: {
            transport_terminal_name: { _ilike: $name }
          }
        }
      }
    ) {
      amount
      created_at
      fk_admin_id
      admin_account {
        username
        fk_assigned_terminal_id
      }
      fk_driver_service_id
      fk_passenger_service_id
      passenger_service {
        service_name
      }
      id
      note
      payment_type
    }
    pos_payment_history_aggregate(
      where: {
        admin_account: {
          transport_terminal_information: {
            transport_terminal_name: { _ilike: $name }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_POS_PAYMENT_HISTORY = gql`
  query GetPosPaymentHistory($limit: Int = 10, $offset: Int = 0) {
    pos_payment_history(
      limit: $limit
      offset: $offset
      order_by: { id: desc }
    ) {
      amount
      created_at
      fk_admin_id
      admin_account {
        username
        fk_assigned_terminal_id
      }
      fk_driver_service_id
      fk_passenger_service_id
      passenger_service {
        service_name
      }
      id
      note
      payment_type
    }
    pos_payment_history_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_POS_PAYMENT_HISTORY_BY_DATE = gql`
  query MyQuery(
    $startDate: timestamptz, 
    $endDate: timestamptz, 
    $limit: Int, 
    $offset: Int
  ) {
    pos_payment_history(
      where: {
        created_at: { _gte: $startDate, _lt: $endDate }
      },
      order_by: { id: desc },
      limit: $limit,
      offset: $offset
    ) {
      amount
      created_at
      fk_admin_id
      admin_account {
        username
        fk_assigned_terminal_id
      }
      fk_driver_service_id
      fk_passenger_service_id
      passenger_service {
        service_name
      }
      id
      note
      payment_type
    }
    pos_payment_history_aggregate(
      where: {
        created_at: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const WAllETS = gql`
  query MyQuery($limit: Int, $offset: Int, $name: String) {
    passenger_services_wallets_history(
      limit: $limit
      offset: $offset
      order_by: { id: desc }
    ) {
      fk_passenger_id
      transaction_amount
      updated_passenger_wallet_amount
      notes
    }
    passenger_services_wallets_history_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const PTPPASSENGERLOG = gql`
  query MyQuery($startId: Int!, $endId: Int!, $offset: Int!, $limit: Int!) {
    ptp_tickets(
      where: {
        _or: [
          { ptp_trip: { ptp_route: { fk_start_terminal: { _eq: $startId } } } }
          { ptp_trip: { ptp_route: { fk_end_terminal: { _eq: $endId } } } }
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      boarded
      created_at
      fk_admin_id
      fk_passenger_id
      fk_ptp_trip_id
      fk_registered_passenger_type_id
      paid
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      seat_number
      ticket_cost
      updated_at
      ticket_passenger_type {
        passenger_type_name
      }
    }
    ptp_tickets_aggregate(
      where: {
        _or: [
          { ptp_trip: { ptp_route: { fk_start_terminal: { _eq: $startId } } } }
          { ptp_trip: { ptp_route: { fk_end_terminal: { _eq: $endId } } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const TRANSPORTTERMINALSNAME = gql`
  query MyQuery {
    transport_terminal_information {
      transport_terminal_id
      transport_terminal_name
    }
  }
`;

export const TRANSPORTTERMINALSNAMEANDID = gql`
  query MyQuery($id: Int!) {
    transport_terminal_information(
      where: { transport_terminal_id: { _eq: $id } }
    ) {
      transport_terminal_id
    }
  }
`;

export const NONPTPPASSENGERLOG = gql`
  query MyQuery(
    $start: String!
    $destination: String!
    $offset: Int!
    $limit: Int!
  ) {
    segment_tickets(
      where: {
        _or: [
          { start: { _eq: $start } }
          { destination: { _eq: $destination } }
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      boarded
      created_at
      destination
      fk_admin_id
      fk_driver_id
      fk_passenger_id
      fk_registered_passenger_type_id
      fk_segment_main_route_trips_id
      id
      registered_date_of_birth
      registered_government_id
      registered_name
      registered_phone_number
      start
      ticket_cost
      ticket_expire_time
      updated_at
      ticket_passenger_type {
        passenger_type_name
      }
    }
  }
`;

export const ADDTRIP = gql`
  mutation MyMutation(
    $routeId: Int!
    $price: numeric!
    $vehicleNo: String!
    $maxSeat: Int!
    $reservedSeat: Int!
    $noOfSeatColumn: Int!
    $departTime: timestamptz
  ) {
    insert_ptp_trips_one(
      object: {
        fk_ptp_route_id: $routeId
        price: $price
        vehicle_number: $vehicleNo
        max_seats: 10
        reserved_seats: 10
        numbers_of_seat_column: 10
        depart_time: $departTime
      }
    ) {
      created_at
      depart_time
      fk_ptp_route_id
      id
      price
      updated_at
      vehicle_number
    }
  }
`;

export const ADDTRIPS = gql`
  mutation MyMutation($payload: [ptp_trips_insert_input!]!) {
    insert_ptp_trips(objects: $payload) {
      affected_rows
    }
  }
`;

export const UPDATEVEHICLE = gql`
  mutation MyMutation($vehicleNo: String!, $segmentRouteId: Int) {
    update_vehicle_accounts(
      where: { vehicle_plate_number: { _eq: $vehicleNo } }
      _set: { fk_assigned_segment_route_id: $segmentRouteId }
    ) {
      affected_rows
    }
  }
`;

export const UPDATEVEHICLERFID = gql`
  mutation MyMutation($vehicleNo: String!, $rfid: String!) {
    update_vehicle_accounts(
      where: { vehicle_plate_number: { _eq: $vehicleNo } }
      _set: { vehicle_rfid_tag: $rfid }
    ) {
      affected_rows
    }
  }
`;

export const TERMINALSTAFFS = gql`
  query MyQuery($category: String!, $terminalId: Int!) {
    admin_accounts(
      where: {
        _and: [
          { admin_category: { _eq: $category } }
          { fk_assigned_terminal_id: { _eq: $terminalId } }
        ]
      }
    ) {
      active
      address
      admin_category
      fk_assigned_terminal_id
      full_name
      id
      phone
      username
    }
  }
`;

// Operator

export const REGISTERVEHICLE = gql`
  mutation MyMutation(
    $vehicleType: String!
    $plateNumber: String!
    $ownerId: String!
  ) {
    insert_vehicle_accounts_one(
      object: {
        fk_vehicle_type: $vehicleType
        vehicle_plate_number: $plateNumber
        fk_vehicle_owner: $ownerId
      }
    ) {
      active
    }
  }
`;

export const VEHICLETYPE = gql`
  query MyQuery {
    vehicle_type_and_information(where: { active: { _eq: true } }) {
      vehicle_type
      ptp_price_per_length
    }
  }
`;

export const VEHICLESBYTYPE = gql`
  query MyQuery($type: String) {
    vehicle_accounts(
      where: {
        active: { _eq: false }
        fk_vehicle_type: { _eq: $type }
        fk_assigned_segment_route_id: { _is_null: true }
      }
    ) {
      fk_vehicle_type
      vehicle_plate_number
    }
  }
`;
export const OPERATORVEHICLES = gql`
  query MyQuery($search: String, $operator: Int) {
    vehicle_accounts(
      where: {
        _and: [
          { fk_operator_id: { _eq: $operator } }
          { vehicle_plate_number: { _ilike: $search } }
        ]
      }
      order_by: { active: asc }
    ) {
      fk_assigned_segment_route_id
      driver {
        first_name
        last_name
      }
      active
      admin_account {
        full_name
      }
      fk_vehicle_type
      vehicle_type_and_information {
        vehicle_type
        ptp_price_per_length
      }
      vehicle_plate_number
      fk_operator_id
      vehicle_rfid_tag
    }
  }
`;

export const DRIVERLIST = gql`
  query MyQuery($id: Int!) {
    driver(where: { fk_assigner_admin_id: { _eq: $id } }) {
      driver_id
      active
      address
      biometrics_photo_fingerprint
      date_of_birth
      email_address
      first_name
      gender
      last_name
      phone
      driving_license_number
      vehicle_accounts {
        vehicle_plate_number
        fk_vehicle_owner
        admin_account {
          full_name
        }
      }
    }
  }
`;

export const DRIVERLISTBYID = gql`
  query MyQuery($id: Int!) {
    driver_by_pk(driver_id: $id) {
      driver_id
      date_of_birth
      email_address
      first_name
      last_name
      phone
      driving_license_number
    }
  }
`;

export const EDIT_DRIVERLIST = gql`
  mutation MyMutation(
    $id: Int
    $dateOfBirth: date!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $drivingLicenseNumber: String!
    $email_address: String
    $address: String
  ) {
    update_driver(
      where: { driver_id: { _eq: $id } }
      _set: {
        date_of_birth: $dateOfBirth
        first_name: $firstName
        last_name: $lastName
        phone: $phone
        driving_license_number: $drivingLicenseNumber
        email_address: $email_address
        address: $address
      }
    ) {
      affected_rows
    }
  }
`;

export const TRIPS = gql`
  query MyQuery(
    $start: timestamptz
    $end: timestamptz
    $startTerminal: String
    $endTerminal: String
  ) {
    ptp_trips(
      order_by: { depart_time: desc }
      where: {
        depart_time: { _gte: $start, _lt: $end }
        ptp_route: {
          _or: {
            start_terminal: {
              transport_terminal_name: { _ilike: $startTerminal }
            }
            end_terminal: { transport_terminal_name: { _ilike: $endTerminal } }
          }
        }
      }
    ) {
      id
      depart_time
      ptp_route {
        start_terminal {
          transport_terminal_name
        }
        end_terminal {
          transport_terminal_name
        }
      }
    }
  }
`;

export const ASSIGNTRIP = gql`
  mutation MyMutation($id: Int, $vehicleNumber: String) {
    update_ptp_trips(
      where: { id: { _eq: $id } }
      _set: { vehicle_number: $vehicleNumber }
    ) {
      affected_rows
    }
  }
`;

export const ASSIGNVEHICLE = gql`
  mutation MyMutation($plateNumber: String, $driverID: Int) {
    update_vehicle_accounts(
      where: { vehicle_plate_number: { _eq: $plateNumber } }
      _set: { fk_driver_id: $driverID }
    ) {
      affected_rows
    }
  }
`;

export const FINDVEHICLE = gql`
  query MyQuery($plateNumber: String) {
    vehicle_location_data(
      limit: 1
      order_by: { updated_at: desc }
      where: { fk_vehicle_plate_number: { _eq: $plateNumber } }
    ) {
      created_at
      fk_vehicle_plate_number
      id
      latitude
      longitude
      updated_at
    }
  }
`;

export const TRACEVEHICLE = gql`
  query MyQuery(
    $plateNumber: String
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    vehicle_location_data(
      order_by: { updated_at: desc }
      where: {
        fk_vehicle_plate_number: { _eq: $plateNumber }
        created_at: { _gt: $startDate, _lt: $endDate }
      }
    ) {
      created_at
      fk_vehicle_plate_number
      id
      latitude
      longitude
      updated_at
    }
  }
`;

// merchant //

export const GETMERCHANTS = gql`
  query MyQuery {
    merchant_accounts {
      id
      merchant_banner_url
      merchant_loyalty_points
      merchant_site_url
      merchant_survey_link
    }
  }
`;

export const EDITMERCHANT = gql`
  mutation MyMutation(
    $id: Int
    $banner: String
    $site: String
    $survery: String
  ) {
    update_merchant_accounts(
      where: { id: { _eq: $id } }
      _set: {
        merchant_banner_url: $banner
        merchant_site_url: $site
        merchant_survey_link: $survery
      }
    ) {
      affected_rows
    }
  }
`;

export const EDITMERCHANTPOINTS = gql`
  mutation MyMutation($id: Int, $point: Int) {
    update_merchant_accounts(
      where: { id: { _eq: $id } }
      _set: { merchant_loyalty_points: $point }
    ) {
      affected_rows
    }
  }
`;

export const GETQRS = gql`
  query MyQuery {
    merchant_loyalty_qr_codes {
      active
      created_at
      fk_merchant_id
      id
      note
      request_point_amount
    }
  }
`;

export const ACTIVATEQR = gql`
  mutation MyMutation($id: uuid, $isActivate: Boolean) {
    update_merchant_loyalty_qr_codes(
      where: { id: { _eq: $id } }
      _set: { active: $isActivate }
    ) {
      affected_rows
    }
  }
`;

// ptp_tickets not found
// export const BOUGHTPTPTICKETS = gql`
//   query MyQuery ( $id: Int! ) {
//     ptp_tickets(where: { fk_admin_id: { _eq: $id } }) {
//       boarded
//       created_at
//       fk_admin_id
//       id
//       paid
//       seat_number
//       registered_date_of_birth
//       registerted_government_id
//       registered_name
//       registered_phone_number
//       ptp_trip {
//         depart_time
//         vehicle_account {
//           fk_vehicle_type
//           vehicle_plate_number
//         }
//         ptp_route {
//           start_terminal {
//             transport_terminal_name
//           }
//           end_terminal {
//             transport_terminal_name
//           }
//         }
//       }
//       ticket_cost
//       ticket_passenger_type {
//         passenger_type_name
//       }
//     }
//   }
// `

// 25
