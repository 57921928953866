import { Add, AddCircleOutlineRounded } from "@mui/icons-material";
import { Autocomplete, Button, colors, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import AutoCompleteTextFieldWithSuggestions from "../../components/AutoCompleteTextFieldWithSuggestions";
import VehicleFeeType from "../VehicleFees/VehicleFeeType";
import FilterType from "../../components/FilterType";
import { TextInput } from "react-native-web"
import DialogMode from "./DialogMode";
import { useLazyQuery } from "@apollo/client";
import { GET_ADMIN_NAME_BY_ID } from "../../query";


const AddFeeDialog = ({ onSubmit, onCancel, onDelete, mode, editData, show = false }) => {
    const [open, setOpen] = useState(show)
    const [terminalName, setTerminalName] = useState(null)
    const [destination, setDestination] = useState(null)
    const [adminId, setAdminId] = useState(null)
    const [cost, setCost] = useState(null)
    const [dialogMode, setDialogMode] = useState(mode)

    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)
    const [admin, setAdmin] = useState(null)

    const [getAdminNameById] = useLazyQuery(GET_ADMIN_NAME_BY_ID, {
        onCompleted: data => {
            console.log('admin: ', data.admin_accounts[0])
            setAdminId(data.admin_accounts[0].username)
            setAdmin(data.admin_accounts[0])
        },
        onError: err => {
            console.log('errr: ', err.cause)
        }
    })

    useEffect(() => {
        setDialogMode(mode)
    }, [mode])

    useEffect(() => {
        if (editData?.fk_admin_id) getAdminNameById({
            variables: { id: editData.fk_admin_id }
        })
    },[editData])

    useEffect(() => {
        switch (mode) {
            case DialogMode.Add: {
                setTitle("Add New Entry Fee")
                setDescription("Please fill all fields");
                setTerminalName("")
                setDestination("")
                setAdminId("")
                setCost("")
                break;
            }
            case DialogMode.Edit: {
                setTitle("Edit")
                setDescription("Edit any field")

                setTerminalName(editData?.fk_transport_terminal_name)
                setDestination(editData?.fk_destination_name)
                setCost(editData?.entry_fee_value)
                
                break;
            }
        }
    }, [dialogMode])

    const handleClickOpen = () => {
        setDialogMode(DialogMode.Add)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        onCancel()
    };

    useEffect(() => {
        setOpen(show)
    }, [show])

    const handleApply = () => {
        if (dialogMode == DialogMode.Add) {
            if (!terminalName || !destination || !cost) {
                alert('Please fill in all fields.');
                return;
            } else {
                onSubmit({
                    terminalName: terminalName,
                    destination: destination,
                    adminId: admin.id,
                    cost: cost
                })
                setOpen(false)
            }
        } else {
            onSubmit({
                id: editData.id,
                terminalName: terminalName,
                destination: destination,
                adminId: admin.id,
                cost: cost
            })
            setOpen(false)
        }
    }

    const handleAdminId = (admin) => {
        setAdmin(admin)
        if (admin != null) setAdminId(admin.username)
    }

    const handleDeleteClick = () => {
        setOpen(false)
        onDelete(editData.id)
    }

    return (
        <div style={{ padding: 16 }}>
            <Button variant="contained" onClick={handleClickOpen} endIcon={<Add />}>Add New Fee</Button>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{description}</DialogContentText>
                    <AutoCompleteTextFieldWithSuggestions text={terminalName} dialogType={VehicleFeeType.EntryFeeValue} filterType={FilterType.FEE_VALUE_TERMINAL_NAME} onSelect={name => setTerminalName(name)} />
                    <AutoCompleteTextFieldWithSuggestions text={destination} dialogType={VehicleFeeType.EntryFeeValue} filterType={FilterType.FEE_VALUE_DESTINATION} onSelect={name => setDestination(name)} />
                    <AutoCompleteTextFieldWithSuggestions text={adminId} dialogType={VehicleFeeType.EntryFeeValue} filterType={FilterType.FEE_ADMIN_ID} onSelect={handleAdminId} />
                    <Autocomplete
                        freeSolo
                        options={[]}
                        value={cost}
                        onInputChange={(event, newInputValue) => {
                            setCost(newInputValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Cost"
                                margin="dense"
                                fullWidth
                                variant="outlined"
                            />
                        )}
                    />

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {
                            (dialogMode == DialogMode.Edit) ? (
                                <Button onClick={handleDeleteClick}>Delete</Button>
                            ) : (<div></div>)
                        }
                        <DialogActions style={{ alignItems: 'center' }}>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleApply}>Apply</Button>
                        </DialogActions>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddFeeDialog;