import { Box, Breadcrumbs, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ScreenTitles from "../../utils/ScreenTitles";
import AddFeeDialog from "./AddFeeDialog";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_ENTRY_FEE_VALUE, DELETE_VEHICLE_ENTRY_FEE_VALUES, GET_ALL_ENTRY_FEE_VALUE, UPDATE_ENTRY_FEE_VALUE } from "../../query";
import Loading from "../../components/Loading";
import { Edit } from "@mui/icons-material";
import DialogMode from "./DialogMode";

const EntryFeeValueScreen = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [showDialog, setOpen] = useState(false)
    const [mode, setMode] = useState(DialogMode.Add)
    const [editData, setEditData] = useState(null)

    const [getAllEntryFeeValues] = useLazyQuery(GET_ALL_ENTRY_FEE_VALUE, {
        onCompleted: data => {
            console.log('list: ', data)
            setTimeout(() => {
                setLoading(false)
                setData(data.vehicle_entry_fee_values)
                setTotalPages(data.total.aggregate.count)
            }, 500)
        }
    })

    useEffect(() => {
        setLoading(true)
        getAllEntryFeeValues({
            variables: {
                limit: limit,
                offset: offset
            }
        })
    }, [])


    const [addEntryFeeValue] = useMutation(ADD_ENTRY_FEE_VALUE, {
        refetchQueries: [{
            query: GET_ALL_ENTRY_FEE_VALUE,
            variables: { limit: limit, offset: offset }
        }],
        onCompleted: data => {
            setData([])
            setLoading(true)
            setTimeout(() => {
                getAllEntryFeeValues({
                    variables: {
                        limit: limit,
                        offset: offset
                    }
                })
            }, 300);
        },
        onError: err => {
            setLoading(false)
            console.log('error: ', err.cause)
        }
    })

    const [updateEntryFeeValue] = useMutation(UPDATE_ENTRY_FEE_VALUE, {
        refetchQueries: [{
            query: GET_ALL_ENTRY_FEE_VALUE,
            variables: { limit: limit, offset: offset }
        }],
        onCompleted: data => {
            console.log('updated successfully.')
            setLoading(true)
            setTimeout(() => {
                getAllEntryFeeValues({
                    variables: {
                        limit: limit,
                        offset: offset
                    }
                })
            }, 300);
        },
        onError: err => {
            console.log('error: ', err.cause)
        }
    })

    const [deleteEntryFeeValue] = useMutation(DELETE_VEHICLE_ENTRY_FEE_VALUES, {
        refetchQueries: [{
            query: GET_ALL_ENTRY_FEE_VALUE,
            variables: { limit: limit, offset: offset }
        }],
        onCompleted: data => {
            console.log('deleted successfully.')
            setLoading(true)
            setTimeout(() => {
                getAllEntryFeeValues({
                variables: {
                    limit: limit,
                    offset: offset
                }
            })
            },300)
        },
        onError: err => {
            console.log('error: ', err.cause)
        }
    })

    const handleSubmit = (item) => {
        console.log(item)
        setOpen(false)
        if (mode == DialogMode.Add) {
            addEntryFeeValue({
                variables: {
                    fk_admin_id: item.adminId,
                    fk_destination_name: item.destination,
                    fk_transport_terminal_name: item.terminalName,
                    entry_fee_value: item.cost
                }
            })
        } else {
            updateEntryFeeValue({
                variables: {
                    id: item.id,
                    adminId: item.adminId,
                    destinationName: item.destination,
                    transportTerminalName: item.terminalName,
                    entryFeeValue: item.cost
                }
            })
        }
        setEditData(null)
    }

    useEffect(() => {
        getAllEntryFeeValues({
            variables: {
                limit: limit,
                offset: offset
            }
        })
    }, [limit, offset])

    if (loading) return (<Loading />)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setOffset(limit * newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(+event.target.value);
        setPage(0);
    };

    const handleEditClick = (data) => {
        setOpen(true)
        setMode(DialogMode.Edit)
        setEditData(data)
    }

    const handleDeleteClick = (id) => {
        setMode(DialogMode.Add)
        setEditData(null)
        setOpen(false)
        deleteEntryFeeValue({
            variables: {
                id: id
            }
        })
    }

    return (
        <div>
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/">Dashboard</Link>
                    <Link to="#">Entry Fee Values</Link>
                </Breadcrumbs>
            </div>

            <Box
                sx={{
                    display: "flex",
                    "& > :not(style)": {
                        m: 1,
                        width: "100%",
                        minHeight: "25vh",
                    },
                    flexDirection: "column",
                }}
            >
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <div style={{ width: "100%", display: "flex", flex: '1', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h4" component="h2" sx={{ m: 3 }}>{ScreenTitles.EntryFeeValues}</Typography>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginLeft: 20, marginRight: 20 }}>
                                <AddFeeDialog show={showDialog} mode={mode} editData={editData} onDelete={handleDeleteClick} onSubmit={handleSubmit} onCancel={() => {
                                    setMode(DialogMode.Add)
                                    setOpen(false)
                                    setEditData(null)
                                }} />
                            </div>
                        </div>
                    </div>
                    <TableContainer sx={{ maxHeight: "75vh" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center' }}>ID</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Terminal Name</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Destination</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Cost</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Admin ID</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Created At</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((t, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={t.id}>
                                        <TableCell style={{ flex: 1, textAlign: 'center' }}>{t.id}</TableCell>
                                        <TableCell style={{ flex: 1, textAlign: 'center' }}>{t.fk_transport_terminal_name}</TableCell>
                                        <TableCell style={{ flex: 1, textAlign: 'center' }}>{t.fk_destination_name}</TableCell>
                                        <TableCell style={{ flex: 1, textAlign: 'center' }}>{t.entry_fee_value}</TableCell>
                                        <TableCell style={{ flex: 1, textAlign: 'center' }}>{t.fk_admin_id}</TableCell>
                                        <TableCell style={{ flex: 1, textAlign: 'center' }}>{convertIsoToLocaleString(t.created_at)}</TableCell>
                                        <TableCell style={{ flex: 1, width: 60, textAlign: 'center' }}>
                                            <IconButton onClick={() => handleEditClick(t)}>
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={totalPages}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ flexShrink: 0 }}
                        />
                    </TableContainer>
                </Paper>

            </Box>
        </div>
    )
}

const convertIsoToLocaleString = (paymentDate) => {
    try {
        const date = new Date(paymentDate);  // Parse the ISO string into a Date object
        return date.toLocaleString('en-US', {
            timeZone: 'UTC', // Optional: specify the time zone if needed
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            //second: '2-digit',
            //fractionalSecondDigits: 3 // For milliseconds
        });
    } catch (e) {
        return "-"
    }
};

export default EntryFeeValueScreen;