import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useLazyQuery} from "@apollo/client";
import FilterType from './FilterType'
import {
    createDynamicQuery,
    createEntryFeeQuery,
    createEntryFeeValueQuery,
    createMemberShipQuery,
    createSearchQuery,
    createTerminalUpdateQuery, GET_ACCREDITATION_FEE_DETAILS
} from "../query";
import VehicleFeeType from '../views/VehicleFees/VehicleFeeType';
import {daDK} from "@mui/x-date-pickers";

const AutoCompleteTextFieldWithSuggestions = ({dialogType, isEdit = false, text = "", filterType, onSelect}) => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([])
    const [parameter, setParameter] = useState()
    const [list, setList] = useState([])
    const [input, setInput] = useState("")
    // Dynamic query
    let GET_FILTER_SUGGESTIONS = GET_ACCREDITATION_FEE_DETAILS;

    console.log("dialogType", isEdit);

    const handleParameter = (() => {
        let argument = ''
        switch (filterType) {
            case FilterType.ADMIN_ID:
                argument = `${inputValue}`;
                break;
            case FilterType.VEHICLE_NUMBER:
                argument = `${inputValue}%`;
                break;
            case FilterType.ID:
                argument = `${inputValue}`;
                break;
            case FilterType.PAYMENT_TYPE:
                argument = `${inputValue}%`;
                break;
            case FilterType.DESTINATION:
                argument = `${inputValue}%`;
                break;
            case FilterType.TERMINAL_NAME:
                argument = `${inputValue}%`;
                break;
            case FilterType.FEE_VALUE_TERMINAL_NAME:
                argument = `${inputValue}%`;
                break;
            case FilterType.FEE_VALUE_DESTINATION:
                argument = `${inputValue}%`;
                break;
            case FilterType.FEE_ADMIN_ID:
                argument = `${inputValue}%`;
                break;

            case FilterType.TERMINAL_ADDRESS:
                argument = `${inputValue}%`;
                break;
            case FilterType.EMAIL_ADDRESS:
                argument = `${inputValue}%`;
                break;
            case FilterType.CONTACTOR_NUMBER:
                argument = `${inputValue}%`;
                break;
            case FilterType.ACCREDITATION_FEE:
                argument = `${inputValue}`;
                break;
            case FilterType.MEMBERSHIP_FEE:
                argument = `${inputValue}`;
                break;
            case FilterType.ALL_DESTINATION: argument = `${inputValue}%`; break;
            case FilterType.ALL_PAYMENT_TYPE: argument = `${inputValue}%`; break;
            case FilterType.ALL_ADMIN_ID: argument = `${inputValue}%`; break;
            case FilterType.ALL_PLATE_NUMBER: argument = `${inputValue}%`; break;
            case FilterType.ALL_BARRIER_GATE_ID: argument = `${inputValue}%`; break;
            default:
                argument = "";
                break;
        }
        return argument
    })

    useEffect(() => {
        setInput(text)
    }, [text])

    switch (dialogType) {
        case VehicleFeeType.AccreditationFee: {
            if (isEdit) {
                GET_FILTER_SUGGESTIONS = createSearchQuery(filterType)
            } else  {
                GET_FILTER_SUGGESTIONS = createDynamicQuery(filterType);
            }
            break;
        }
        case VehicleFeeType.MembershipFee: {
            if (isEdit) {
                GET_FILTER_SUGGESTIONS = createSearchQuery(filterType)
            } else  {
                GET_FILTER_SUGGESTIONS = createMemberShipQuery(filterType);
            }
            break;
        }
        case VehicleFeeType.DispatchFee: {
            if (isEdit) {
                GET_FILTER_SUGGESTIONS = createSearchQuery(filterType)
            } else  {
                GET_FILTER_SUGGESTIONS = createEntryFeeQuery(filterType);
            }
            break;
        }
        case VehicleFeeType.EntryFeeValue:
            GET_FILTER_SUGGESTIONS = createEntryFeeValueQuery(filterType);
            break;
        case VehicleFeeType.Terminal:
            GET_FILTER_SUGGESTIONS = createTerminalUpdateQuery(filterType);
            break;
    }

    const handleSearchResponse = (data) => {
        console.log(data.transport_terminal_information.map((item) => item.transport_terminal_name))
        switch (filterType) {
            case FilterType.ALL_DESTINATION: setSuggestions(data.transport_terminal_information.map((item) => item.transport_terminal_name)) ;break;
            default: setSuggestions([])
        }
    }

    const [getFilterSuggestions] = useLazyQuery(GET_FILTER_SUGGESTIONS, {
        onCompleted: data => {
            if (isEdit === true) {
                handleSearchResponse(data)
            } else  {
                switch (dialogType) {
                    case VehicleFeeType.AccreditationFee:
                        setAllSuggestions(data.vehicle_accreditation_fee_history);
                        break;
                    case VehicleFeeType.MembershipFee:
                        setAllSuggestions(data.vechicle_membership_fee_history);
                        break;
                    case VehicleFeeType.DispatchFee:
                        setAllSuggestions(data.vehicle_entry_fee_history);
                        break;
                    case VehicleFeeType.Terminal:
                        setAllSuggestions(data.transport_terminal_information);
                        break;
                    case VehicleFeeType.EntryFeeValue:
                        handleEntryFeeData(data);
                        break;
                    default:
                        setAllSuggestions([])
                }
            }
        },
        onError: err => {
            console.log('auto: ', err.cause.message)
        }
    });

    const handleEntryFeeData = (data) => {
        switch (filterType) {
            case FilterType.FEE_ADMIN_ID: {
                setAllAccreditationFee(data.admin_accounts);
                if (filterType == FilterType.FEE_ADMIN_ID) setList(data.admin_accounts)
                break;
            }
            case FilterType.FEE_VALUE_DESTINATION:
                setAllAccreditationFee(data.transport_terminal_information);
                break;
            case FilterType.FEE_VALUE_TERMINAL_NAME:
                setAllAccreditationFee(data.transport_terminal_information);
                break;
        }
    }

    const setAllSuggestions = ((data) => {
        switch (dialogType) {
            case VehicleFeeType.AccreditationFee:
                setAllAccreditationFee(data);
                break;
            case VehicleFeeType.MembershipFee:
                setAllAccreditationFee(data);
                break;
            case VehicleFeeType.DispatchFee:
                setAllAccreditationFee(data);
                break;
            case VehicleFeeType.EntryFeeValue:
                setAllAccreditationFee(data);
                break;
            case VehicleFeeType.Terminal:
                setAllAccreditationFee(data);
                break;
            default:
                setAllAccreditationFee(null);
                break;
        }
    })


    const setAllAccreditationFee = ((data) => {
        switch (filterType) {
            case FilterType.ID:
                setSuggestions(data.map(item => item.id));
                break;
            case FilterType.VEHICLE_NUMBER:
                setSuggestions(data.map(item => item.fk_vehicle_plate_number));
                break;
            case FilterType.AMOUNT:
                setSuggestions(data.map(item => item.amount));
                break;
            case FilterType.PAYMENT_TYPE:
                setSuggestions(data.map(item => item.payment_type));
                break;
            case FilterType.ADMIN_ID:
                setSuggestions(data.map(item => item.fk_admin_id));
                break;
            case FilterType.DESTINATION:
                setSuggestions(data.map(item => item.destination));
                break;
            case FilterType.TERMINAL_NAME:
                setSuggestions(data.map(item => item.barrier_gate_account.fk_transport_terminal_name));
                break;
            case FilterType.BARRIER_GATE_ID:
                setSuggestions(data.map(item => item.fk_barrier_gate_id));
                break;
            case FilterType.FEE_VALUE_TERMINAL_NAME:
                setSuggestions(data.map(item => item.transport_terminal_name));
                break;
            case FilterType.FEE_VALUE_DESTINATION:
                setSuggestions(data.map(item => item.transport_terminal_name));
                break;
            case FilterType.FEE_ADMIN_ID:
                setSuggestions(data.map(item => item.username));
                break;

            case FilterType.TERMINAL_ADDRESS:
                setSuggestions(data.map(item => item.transport_terminal_address));
                break;
            case FilterType.ACCREDITATION_FEE:
                setSuggestions(data.map(item => item.accreditation_fee_amount));
                break;
            case FilterType.MEMBERSHIP_FEE:
                setSuggestions(data.map(item => item.monthly_fee_amount));
                break;
            case FilterType.EMAIL_ADDRESS:
                setSuggestions(data.map(item => item.email_address));
                break;
            case FilterType.CONTACTOR_NUMBER:
                setSuggestions(data.map(item => item.contactor_number));
                break;
            default:
                setSuggestions([])
        }
    })

    useEffect(() => {
        const argument = handleParameter()
        console.log(`argument: ${argument}`)
        getFilterSuggestions({variables: {parameter: argument}}, [inputValue]);

    }, [inputValue]);

    const handleOptionSelect = (event, newValue) => {
        try {
            if (filterType == FilterType.FEE_ADMIN_ID) {
                const admin = list.find(item => item.username == newValue)
                onSelect(admin)
                return;
            }
        } catch (err) {
        }
        if (onSelect) {
            onSelect(newValue);
        }
    };

    return (
        <Autocomplete
            freeSolo
            value={input}
            options={suggestions}
            onChange={handleOptionSelect}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                // enable to update only when terminal
                if (dialogType == VehicleFeeType.Terminal) handleOptionSelect(event, newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={filterType}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                />
            )}
        />
    );
};


export default AutoCompleteTextFieldWithSuggestions;
