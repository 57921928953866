import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import App from "./App";

import {
  ApolloClient, ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const authLink = setContext((_, { headers }) => {
  const loggedAuthorJSON = window.localStorage.getItem("loggedAdmin");
  const result = JSON.parse(loggedAuthorJSON);
  return {
    headers: {
      ...headers,
      Authorization: result ? `Bearer ${result.token}` : null,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (
        extensions.code === "invalid-headers" ||
        extensions.code === "invalid-jwt"
      ) {
        window.location.assign(`${window.location.origin}/login`);
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    alert("network connection problem");
  }
});

const httpLink = new HttpLink({ uri: "https://api.aicpass.com/v1/graphql" });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorLink.concat(authLink).concat(httpLink),
});

// window.addEventListener('beforeunload', (e) => {
//   e.preventDefault();
//   window.localStorage.removeItem('loggedAdmin')
//   return
// })

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);

// fallback: {
//   "buffer": require.resolve("buffer/"),
//   "util": require.resolve("util/"),
//   "stream":  require.resolve("stream-browserify"),
//    "crypto": require.resolve("crypto-browserify")
// },
