import React, { useEffect, useState, lazy, Suspense } from "react";
import { useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { setToken, setUser } from "./services/login";

// import Dashboard from './views/Dashboard';
// import BoughtTickets from './views/PTPTickets/BoughtTickets';

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import VehicleFeeType from "./views/VehicleFees/VehicleFeeType";
import Header from "./layout/Header";
import SideBar from "./layout/SideBar";
import ScreenRoutes from "./utils/Routes";
import EntryFeeValueScreen from "./views/Fees/EntryFeeValue";
const SegmentsList = lazy(() => import("./views/SegmentRoutes/SegmentsList"));
const Profile = lazy(() => import("./views/Profile"));
const Terminals = lazy(() => import("./views/Terminals/Index"));
const TerminalCreate = lazy(() => import("./views/Terminals/Create"));
const Drivers = lazy(() => import("./views/Drivers/Index"));
const DriversList = lazy(() => import("./views/Drivers/List"));
const UpdateDriver = lazy(() => import("./views/Drivers/Update"));
const Vehicles = lazy(() => import("./views/Vehicles/Index"));
const ApprovedVehicles = lazy(() => import("./views/Vehicles/Approveds"));
const VehiclesList = lazy(() => import("./views/Vehicles/Lists"));
const Routes = lazy(() => import("./views/Route/Index"));
const AtRoutes = lazy(() => import("./views/Route/AtRoutes"));
const CreateRoute = lazy(() => import("./views/Route/Create"));
const PassengerServicesWallets = lazy(() =>
  import("./views/PassengerServicesWallets")
);
const OROAdminWallets = lazy(() => import("./views/Admins/OROAdminWallets"));
const InsurancePTP = lazy(() => import("./views/Insurances/PTPs"));
const CreateInsurance = lazy(() => import("./views/Insurances/Create"));
const InsuranceDelivery = lazy(() => import("./views/Insurances/Deliveries"));
const Transactions = lazy(() => import("./views/Transactions/Index"));
const MerchantQRs = lazy(() => import("./views/Merchants/QRs"));
const Merchants = lazy(() => import("./views/Merchants/Index"));
const ChangePassengerDriver = lazy(() =>
  import("./views/ChangePassengerDriver")
);
const FindVehicle = lazy(() => import("./views/Vehicles/FindVehicle"));
const NonPTPTracing = lazy(() => import("./views/Tracing/NonPTPTracing"));
const PTPTracing = lazy(() => import("./views/Tracing/PTPTracing"));
const CreateTerminalManager = lazy(() =>
  import("./views/CreateTerminalManager")
);
const CreateOperator = lazy(() => import("./views/CreateOperator"));
const CreateMerchant = lazy(() => import("./views/CreateMerchant"));
const Admins = lazy(() => import("./views/Admins/Index"));
const ChangeAppTheme = lazy(() => import("./views/ChangeAppTheme"));
const CreateDriver = lazy(() => import("./views/Drivers/Create"));
const CreateVehicle = lazy(() => import("./views/Vehicles/Create"));
const CreateTerminalStaff = lazy(() => import("./views/CreateTerminalStaff"));
const TerminalTransactions = lazy(() =>
  import("./views/Transactions/TerminalTransactions")
);
// const PTPPassengerLog = lazy(() => import("./views/PTPPassengerLog"));
// const NonPTPPassengerLog = lazy(() => import("./views/NonPTPPassengerLog"));
// const AddTripOperator = lazy(() => import("./views/Trips/AddTripOperator"));
// const TripsList = lazy(() => import("./views/Trips/Lists"));
const Trips = lazy(() => import("./views/Trips/Index"));
// const TripsTerminalManager = lazy(() =>
//   import("./views/Trips/ListsTerminalManager")
// );
const TerminalStaffs = lazy(() => import("./views/Admins/TerminalStaffs"));
const CreateSegment = lazy(() => import("./views/SegmentRoutes/Create"));
const Segments = lazy(() => import("./views/SegmentRoutes/Index"));
const NonPTPDriverByPassenger = lazy(() =>
  import("./views/NonPTPDriverByPassenger")
);
const PassengersAndDriversByTrip = lazy(() =>
  import("./views/PassengersAndDriversByTrip")
);
const ServedPassenger = lazy(() => import("./views/ServedPassengers"));
const Wallets = lazy(() => import("./views/Wallets/Index"));
const VehicleManagementData = lazy(() =>
  import("./views/VehiclesManagement/Index")
);
const BarrierGates = lazy(() => import("./views/BarrierGates/Index"));
const BarrierGatesList = lazy(() => import("./views/BarrierGates/List"));
const VendingMachineTransactions = lazy(() =>
  import("./views/VendingMachineTransactions/Index")
);
const VendingMachineTransactionsList = lazy(() =>
  import("./views/VendingMachineTransactions/List")
);
const VendingMachineAccounts = lazy(() =>
  import("./views/VendingMachineAccounts/Index")
);
const PassengerWalletHistory = lazy(() =>
  import("./views/PassengerWalletHistory/Index")
);
const PassengerWalletTopupHistory = lazy(() =>
  import("./views/PassengerWalletToupHistory/Index")
);
const Passengers = lazy(() => import("./views/Passengers/Index"));
const Passenger = lazy(() => import("./views/Passengers/Passenger"));
// const Tickets = lazy(() => import("./views/Tickets/Index"));
// const Ticket = lazy(() => import("./views/Tickets/Ticket"));
const CreateValidator = lazy(() => import("./views/Validators/Create"));
const ValidatorsList = lazy(() => import("./views/Validators/Lists"));
const Validators = lazy(() => import("./views/Validators/Index"));
const NormalTicketsList = lazy(() => import("./views/NormalTickets/List"));
const OperatorsRoutes = lazy(() => import("./views/OperatorsRoutes/Index"));
const CreateOperatorsRoutes = lazy(() =>
  import("./views/OperatorsRoutes/Create")
);
const NormalTickets = lazy(() => import("./views/NormalTickets/Index"));
const NormalTicket = lazy(() => import("./views/NormalTickets/NormalTicket"));
const NormalTicketUnuseds = lazy(() =>
  import("./views/NormalTickets/UnusedIndex")
);
const Collectors = lazy(() => import("./views/VehicleFeeCollectors/Index"));
const CreateCollector = lazy(() =>
  import("./views/VehicleFeeCollectors/Create")
);
const VehicleFeeInfo = lazy(() => import("./views/VehicleFeeInfo/Index"));
const VehicleTypes = lazy(() => import("./views/VehicleTypes/Index"));

// Vehicle Fee Contents
const VehicleFeeScreen = lazy(() => import("./views/VehicleFees/VehicleFeeScreen"))

const drawerWidth = 340;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Admin = () => {
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState(null);

  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(open ? false : true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const loggedAuthorJSON = window.localStorage.getItem("loggedAdmin");
    if (loggedAuthorJSON) {
      const result = JSON.parse(loggedAuthorJSON);
      setToken(result.token);
      setUser({
        role: result.role,
        username: result.username,
        id: result.id,
        assignedTerminalId: result.assignedTerminalId,
      });
      setRole(result.role);
    } else {
      history.push("/login");
    }
  }, [history]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <SideBar handleDrawerClose={handleDrawerClose} open={open} />
      <Main open={open}>
        <DrawerHeader />
        <Switch>
          <Suspense fallback={<div>Loading...</div>}>
            <Route path="/profile" key="/profile">
              <Profile />
            </Route>
            <Route exact path="/" key="/">
              <Profile />
            </Route>
            {role === "oro_admin" && (
              <>
                <Route path="/transactionsIndex" key="/transactionsIndex">
                  <Transactions />
                </Route>
                <Route path="/terminals" key="/terminals">
                  <Terminals />
                </Route>
                <Route path="/terminalsCreate" key="/terminals/create">
                  <TerminalCreate />
                </Route>
                <Route path="/drivers" key="/drivers">
                  <Drivers />
                </Route>
                <Route path="/vehicles" key="/vehicles">
                  <Vehicles />
                </Route>
                <Route path="/adminWallets">
                  <OROAdminWallets />
                </Route>
                <Route path="/admins" key="/admins">
                  <Admins />
                </Route>
                <Route path="/changeTheme" key="/changeTheme">
                  <ChangeAppTheme />
                </Route>
                <Route
                  path="/terminalManager/create"
                  key="/createterminalManager"
                >
                  <CreateTerminalManager />
                </Route>
                <Route path="/operator/create" key="/operatorCreate">
                  <CreateOperator />
                </Route>
                <Route path="/merchant/create" key="/createMerchant">
                  <CreateMerchant />
                </Route>
                <Route
                  path="/passengerWalletHistory"
                  key="/passengerWalletHistory"
                >
                  <PassengerWalletHistory />
                </Route>
                <Route
                  path="/passengerWalletTopupHistory"
                  key="/passengerWalletTopupHistory"
                >
                  <PassengerWalletTopupHistory />
                </Route>
                {/* <Route path="/createVehicles" key="/createVehicles">
                  <CreateVehicle />
                </Route> */}
                <Route path="/createDrivers" key="/createDrivers">
                  <CreateDriver />
                </Route>
                <Route path="/createTerminalStaff" key="/createTerminalStaff">
                  <CreateTerminalStaff />
                </Route>
                <Route path="/routes" key="/routes">
                  <Routes />
                </Route>
                <Route path="/createRoutes" key="createRoutes">
                  <CreateRoute />
                </Route>
                <Route path="/trips" key="/trips">
                  <Trips />
                </Route>
                <Route
                  path="/nonPTPDriversByPassenger"
                  key="/nonPTPDriversByPassenger"
                >
                  <NonPTPDriverByPassenger />
                </Route>
                <Route path="/servedPassengers" key="/servedPassengers">
                  <ServedPassenger />
                </Route>
                <Route
                  path="/passengersAndDriversByTrip"
                  key="/passengersAndDriversByTrip"
                >
                  <PassengersAndDriversByTrip />
                </Route>
                <Route path="/createSegments" key="/createSegments">
                  <CreateSegment />
                </Route>
                <Route path="/segments" key="/segments">
                  <Segments />
                </Route>
                <Route
                  path="/passengerServicesWallets"
                  key="/passengerServicesWallets"
                >
                  <PassengerServicesWallets />
                </Route>
                <Route path="/walletsIndex" key="/walletsIndex">
                  <Wallets />
                </Route>
                <Route path="/tracing/ptp" key="/tracing/ptp">
                  <PTPTracing />
                </Route>
                <Route path="/tracing/non-ptp" key="/tracing/non-ptp">
                  <NonPTPTracing />
                </Route>
                <Route path="/map/:plateNumber" key="/map">
                  <FindVehicle />
                </Route>
                <Route
                  path="/changePassengerDriver"
                  key="/changePassengerDriver"
                >
                  <ChangePassengerDriver />
                </Route>
                <Route
                  path="/vehicleManagementData"
                  key="/vehicleManagementData"
                >
                  <VehicleManagementData />
                </Route>
                <Route
                  path="/vendingMachineTransactions"
                  key="/vendingMachineTransactions"
                >
                  <VendingMachineTransactions />
                </Route>
                <Route
                  path="/vendingMachineAccounts"
                  key="/vendingMachineAccounts"
                >
                  <VendingMachineAccounts />
                </Route>
                <Route path="/barrierGates" key="/barrierGates">
                  <BarrierGates />
                </Route>
                <Route path="/merchantQRs" key="/merchantQRs">
                  <MerchantQRs />
                </Route>
                <Route path="/merchants" key="/merchants">
                  <Merchants />
                </Route>
                <Route path="/insurances/PTPs" key="/insurances/PTPs">
                  <InsurancePTP />
                </Route>
                <Route
                  path="/insurances/deliveries"
                  key="/insurances/deliveries"
                >
                  <InsuranceDelivery />
                </Route>
                <Route path="/insurances/create" key="/insurances/create">
                  <CreateInsurance />
                </Route>
                <Route path="/passengers" key="/passengers">
                  <Passengers />
                </Route>
                <Route path="/passenger/:id" key="/passenger">
                  <Passenger />
                </Route>
                <Route path="/validators" key="validators">
                  <Validators />
                </Route>
                <Route path="/operatorsRoutes" key="operatorsRoutes">
                  <OperatorsRoutes />
                </Route>
                <Route
                  path="/createOperatorsRoutes"
                  key="createOperatorsRoutes"
                >
                  <CreateOperatorsRoutes />
                </Route>
                <Route path="/vehicleTypes" key="vehicleTypes">
                  <VehicleTypes />
                </Route>
              </>
            )}
            {role === "terminal_manager" && (
              <>
                <Route path="/routes" key="/routes">
                  <AtRoutes />
                </Route>
                {/* <Route path="/addTrip" key="/addTrip">
                  <AddTripOperator />
                </Route> */}
                <Route path="/vehicles/approved" key="/vehicles/approved">
                  <ApprovedVehicles />
                </Route>
                <Route path="/createTerminalStaff" key="/createTerminalStaff">
                  <CreateTerminalStaff />
                </Route>
                <Route path="/terminalTransactions" key="/terminalTranscations">
                  <TerminalTransactions />
                </Route>

                {/* ACCREDITATION FEE CONTENT */}
                <Route path="/accreditationFees" key="/accreditationFees">
                  <VehicleFeeScreen feeType={VehicleFeeType.AccreditationFee} />
                </Route>

                {/* MEMBERSHIP FEE CONTENT */}
                <Route path="/monthlyFees" key="/monthlyFees">
                  <VehicleFeeScreen feeType={VehicleFeeType.MembershipFee} />
                </Route>

                {/* ENTRY FEE CONTENT */}
                <Route path="/dispatchFees" key="/dispatchFees">
                  <VehicleFeeScreen feeType={VehicleFeeType.DispatchFee} />
                </Route>

                <Route path={ScreenRoutes.VehicleEntryFeeValueRoute} key={ScreenRoutes.VehicleEntryFeeValueRoute}>
                  <EntryFeeValueScreen />
                </Route>

                {/* <Route path="/ptpPassengerLogs" key="/ptpPassengerLogs">
                  <PTPPassengerLog />
                </Route>
                <Route path="/nonPtpPassengerLogs" key="/nonPtpPassengerLogs">
                  <NonPTPPassengerLog />
                </Route> */}
                <Route path="/terminalStaffs" key="/terminalStaffs">
                  <TerminalStaffs />
                </Route>
                {/* <Route path="/trips" key="/trips">
                  <TripsTerminalManager />
                </Route> */}
                <Route path="/segments" key="/segments">
                  <SegmentsList />
                </Route>
                <Route path="/barrierGates" key="/barrierGates">
                  <BarrierGatesList />
                </Route>
                <Route
                  path="/vendingMachineTransactions"
                  key="/vendingMachineTransactions"
                >
                  <VendingMachineTransactionsList />
                </Route>
                {/* <Route path="/tickets" key="/tickets">
                  <Tickets />
                </Route>
                <Route path="/ticket/:id" key="/ticket">
                  <Ticket />
                </Route> */}
                <Route path="/normalTickets" key="/normalTickets">
                  <NormalTickets />
                </Route>
                <Route path="/nomralTicketUnuseds" key="normalTicketUnuseds">
                  <NormalTicketUnuseds />
                </Route>
                <Route path="/normalTicket/:id" key="/normalTicket">
                  <NormalTicket />
                </Route>
                <Route path="/createValidators" key="createValidators">
                  <CreateValidator />
                </Route>
                <Route path="/validators" key="validators">
                  <ValidatorsList />
                </Route>
                <Route path="/collectors" key="collectors">
                  <Collectors />
                </Route>
                <Route path="/createCollectors" key="createCollector">
                  <CreateCollector />
                </Route>
                <Route path="/vehicleFeeInfo" key="vehicleFeeInfo">
                  <VehicleFeeInfo />
                </Route>
                <Route path="/operatorsRoutes" key="operatorsRoutes">
                  <OperatorsRoutes />
                </Route>
                <Route
                  path="/createOperatorsRoutes"
                  key="createOperatorsRoutes"
                >
                  <CreateOperatorsRoutes />
                </Route>
              </>
            )}
            {role === "operator" && (
              <>
                {/* <Route path="/addTrip" key="/addTrip">
                  <AddTripOperator />
                </Route>
                <Route path="/trips" key="/trips">
                  <TripsList />
                </Route> */}
                <Route path="/createVehicles" key="/createVehicles">
                  <CreateVehicle />
                </Route>
                <Route path="/createDrivers" key="/createDrivers">
                  <CreateDriver />
                </Route>
                <Route path="/vehicles" key="/vehicles">
                  <VehiclesList />
                </Route>
                <Route path="/drivers" key="/drivers">
                  <DriversList />
                </Route>
                <Route path="/editDriver/:id" key="/editDrivers">
                  <UpdateDriver />
                </Route>
                <Route path="/map/:plateNumber" key="/map">
                  <FindVehicle />
                </Route>
                <Route path="/normalTickets" key="/normalTickets">
                  <NormalTicketsList />
                </Route>
                <Route path="/normalTicket/:id" key="/normalTicket">
                  <NormalTicket />
                </Route>
                {/* <Route path="/createValidators" key="createValidators">
                  <CreateValidator />
                </Route> */}
                {/* <Route path="/validators" key="validators">
                  <ValidatorsList />
                </Route> */}
              </>
            )}
          </Suspense>
        </Switch>
      </Main>
    </Box>
  );
};

export default Admin;
