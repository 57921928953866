class FilterType{
    static ID = "ID"
    static VEHICLE_NUMBER = "Vehicle Number"
    static AMOUNT = "Fee Amount"
    static PAYMENT_TYPE = "Filter Payment Type"
    static ADMIN_ID = "Search Admin ID"
    static DESTINATION = "Search Destination"
    static TERMINAL_NAME = "Search Terminal Name"
    static BARRIER_GATE_ID = "Search Barrier Gate ID"
    static FEE_VALUE_TERMINAL_NAME = "Terminal Name"
    static FEE_VALUE_DESTINATION = "Fee Destination"
    static FEE_ADMIN_ID = "Fee Admin ID"

    static TERMINAL_ADDRESS = "Terminal Address"
    static ACCREDITATION_FEE = "Accreditation Fee"
    static MEMBERSHIP_FEE = "Membership Fee"
    static EMAIL_ADDRESS = "Email Address"
    static CONTACTOR_NUMBER = "Contactor Number"

    static ALL_PLATE_NUMBER = "Vehicle Plate Number"
    static ALL_FEE_AMOUNT = "Amount"
    static ALL_PAYMENT_TYPE = "Payment Type"
    static ALL_ADMIN_ID = "Admin ID"
    static ALL_DESTINATION = "Destination"
    static ALL_BARRIER_GATE_ID = "Barrier Gate ID"

    static USER_NAME = "User Name"

}

export default FilterType;